// styles.scss
@import '../app.scss';
// Define mixin for mobile styling
@mixin mobile {
    @media (max-width: 768px) {
      @content;
    }
  }
  
  .navbar {
    height: 100px;
    background-color: rgb(11, 109, 11);
    color: white;
    .wrapper {
      max-width: 1366px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      @include small {
       
       display: none !important;
       
      }
      @include medium {
        max-width: 1518px;
        .wrapper_span_container {
          flex: 1;
         
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .socials {
          flex: 2;
          display: flex;
          align-items: center;
          justify-content: center;
         
          .socials_a1_container {
            flex: 1;
            margin-top: 10px;
          }
          .socials_dateTime_container {
            flex: 3;
          }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        
      }
    
      // Mobile styling
      @include mobile {
       
       
       
       display: none;
        // flex-direction: column; // Change to column layout on mobile
        // align-items: flex-end; // Align items to the right on mobile
        // padding: 20px;
        .socials {
          display: flex;
          justify-content: center;
          .socials_a1_container {
            display:none;
          }
        }
      }
    .wrapper_span_container {

      span {
        font-weight: bold;
      
        // Hide span on mobile
        @include mobile {
          display: none;
        }
        @include medium{
          // display: none;
        }
       
      }
    }
    
    
      span img {
        width: 18px;
        height: 18px;
      }
    
      .socials {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    
        // Adjust styling for mobile
        @include mobile {
          margin-top: 20px; // Add margin on top for spacing
          justify-content: center; // Center social links on mobile
        }
    
        .facebookLogo_container {
          display: flex;
          align-items: center;
          justify-content: center;
    
          .facebook_logo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            z-index: 5;
          }
        }
    
        .transparency_logo {
          width: 200px;
          height: 40px;
          border-radius: none !important;
        
        }
      }
    }
    
    // .wrapper {
    //   max-width: 1366px;
    //   margin: auto;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   height: 100%;
  
    //   // Mobile styling
    //   @include mobile {
    //     justify-content: flex-end;
    //     padding: 20px;
    //   }
  
    //   span {
    //     font-weight: bold;
  
    //     // Hide span on mobile
    //     @include mobile {
    //       display: none;
    //     }
    //   }
  
    //   span img {
    //     width: 18px;
    //     height: 18px;
    //   }
  
    //   .socials {
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-end;
    //     gap: 20px;
  
    //     .facebookLogo_container {
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
  
    //       .facebook_logo {
    //         width: 40px;
    //         height: 40px;
    //         border-radius: 50%;
    //       }
    //     }
  
    //     .transparency_logo {
    //       width: 200px;
    //       height: 40px;
    //       border-radius: none !important;
    //     }
    //   }
    // }
  }
  
  

// @import '../app.scss';

// .navbar {
//     height: 100px;
//     background-color: rgb(11, 109, 11);
//     .wrapper {
//         max-width: 1366px;
//         margin: auto;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         height: 100%;
       

//         @include mobile {
//             justify-content: flex-end;
//             padding: 20px;
//         }

//         span {
//             font-weight: bold;
//             @include mobile {
//                 display: none;
//             }
//         }
//         span img {
//             width: 18px;
//             height: 18px;
//         }

       

//         .socials {
//             display: flex;
//             align-items: center;
//             justify-content: flex-end;
//             gap: 20px;

//             .facebookLogo_container {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 .facebook_logo {
//                     width: 40px;
//                     height: 40px;
//                     border-radius: 50%;
//                 }
    
               
                
//             }
//             .transparency_logo {
//                 width: 200px;
//                 height: 40px;
//                 border-radius: none !important;
//             }
       
//         }
//     }


// }