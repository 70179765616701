// styles.scss

@import '../app.scss';

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  background-color: white;
  color: white !important;

  .bg {
    position: fixed;
    top: 100px;
    left: 0;
    bottom: 0;
    width: 250px;
    z-index: 999;
    background: rgb(11, 109, 11);
    height: fit-content;
    border-radius: 5px;

    @include mobile {
      width: 200px;
    }

    .logoSubmenuContainer {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .links {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      a {
        font-size: 40px;

        @include mobile {
          font-size: 20px;
        }
      }
    }
  }

  button {
    z-index: 999;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    top: 25px;
    left: 25px;
    background-color: rgb(11, 109, 11);
    border: none;
    color: white;
    cursor: pointer;
  }
}


// @import '../app.scss';

// .sidebar {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     background-color: white ;
//     color: white !important;
    
//     .bg {
       
//         position:  fixed;
//         top:100px;
//         left:0;
//         bottom: 0;
//         width: 250px;
//         z-index: 999;
//         background:rgb(11, 109, 11) ;
//        height: fit-content;
//        border-radius: 5px;

//         @include mobile {
//             width: 200px;
//         }
//         .logoSubmenuContainer {
//             width: 100%;
//             height: 80px;
//             // background-color: blue;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             img {
//                 width: 50px;
//                 height: 50px;
//             }
//         }
//         .links {
//             position: absolute;
//             width: 100%;
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             gap: 20px;
//             a {
//                 font-size: 40px;
//                 @include mobile {
//                     font-size: 20px;
//                 }
//             }
//         }
//     }
//     button {
//         z-index: 999;
//         width: 50px;
//         height: 50px;
//         border-radius: 50%;
//         position: fixed;
//         top: 25px;
//         left: 25px;
//         background-color: rgb(11, 109, 11);
       
//         border: none;
//         color: white;
//         cursor: pointer;
//     }
// }