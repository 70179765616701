@import "../app.scss";

.bacInputData {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }
  .bacInput_container {
    max-width: 1366px;
    width: 1366px;
    padding: 16px;
    margin: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    // LoginCard.scss
    @include mobile {
      width: 100%;
    }
    form {
      width: 80%;
      .form-group {
        margin-bottom: 15px;
        select {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: #f8f8f8;
          font-size: 16px;

          &:focus {
            outline: none;
            border-color: #007bff;
          }
        }
        label {
          display: block;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
      }

      button {
        width: 100%;
        padding: 10px;
        background-color: green;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
