@import "../app.scss";

.BidsAwardedToProceed {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }

  .BidsAwardedToProceed_container {
    max-width: 1366px;
    height: auto;
    margin: auto;
    @include mobile {
      width: 100%;
      padding: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .table-container {
      max-width: 100%;
      overflow-x: auto;
    }

    #dataTable {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      .link {
        padding: 5px 20px;
        background-color: crimson; /* Blue color */
        color: #fff; /* White text color */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      th,
      td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }

      th {
        background-color: green;
        color: white;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      button {
        padding: 8px 16px;
        margin: 0 4px;
        border: none;
        background-color: green;
        color: #fff;
        cursor: pointer;

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }

    #searchInput {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      width: 30%;
      margin-bottom: 10px;
    }

    #searchInput::placeholder {
      color: #999;
    }
    #searchInput:focus {
      border-color: #007bff; /* Change border color when input is focused */
      outline: none; /* Remove the default focus outline */
    }
  }
}
