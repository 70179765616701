@import '../app.scss';
.TouristDestinationsCard_card {
    width: 450px;

    min-height: 615px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 20px;
    color: #333333;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @include mobile {
      width: 100%;
      min-height: 312px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 5px;
      padding: 20px;
      color: #333333;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;
    }
  
    
    .TouristDestinationsCard_card__image {
      width: 100%;
      height: 265px;
      max-height: 265px;
      border-radius: 5px;
      margin-bottom: 15px;
      @include mobile {
        max-width: 100%;
        height: auto;
      }
    
    }
    .TouristDestinationsCard_card__title {
      font-size: 1.5rem;
font-weight: bold;
margin-bottom: 10px;
    }
    .TouristDestinationsCard_card-description {
        width: 100%;
      
        height: fit-content;
        text-align: justify;
        word-wrap: break-word;
    }
    .TouristDestinationsCard_card__content {
        height: fit-content;
        width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      @include mobile {
        margin-top: 10px;
      }
    }
  }