@import '../app.scss';


.cursor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid green;
    position: fixed;
    z-index: 999;


    @include mobile {
        display: none;
    }
}