.bacLogin {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }

  .bacLogin_container {
    max-width: 1366px;

    padding: 16px;
    margin: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    // LoginCard.scss

    .login-card {
      width: 300px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;

      h2 {
        text-align: center;
        margin-bottom: 20px;
      }

      form {
        .form-group {
          margin-bottom: 15px;

          label {
            display: block;
            margin-bottom: 5px;
          }

          input {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 5px;
          }
        }

        button {
          width: 100%;
          padding: 10px;
          background-color: green;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }
}
