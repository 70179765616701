@import '../app.scss';
.assesor {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .assesor_header_container {
        .assesor_img {
            max-width: 100%;
            height: auto;
        }
    }
    .assesor_container {
        max-width: 1366px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    
        
       .assesor_vmg_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @include mobile {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
        }
       }
    }
}