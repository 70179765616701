@import '../app.scss';
.NewsFeed {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    img {
        max-width: 100%;
        height: auto;
        
       }
       .NewsFeed__container {
        max-width: 1366px;
        margin: auto;
        @include mobile {
            width: 100%;
            display: flex;
            height: fit-content !important;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            margin-bottom: 20px;
            flex-direction: column;
            align-items: center;
           }
        .NewsFeed__container_h1 {
            text-align: center;
        }
        .NewsFeed_cards_container {
            width: 100%;
            display: flex;
            align-items: center;justify-content: center;
            flex-wrap: wrap;
            gap: 5px;
            @include mobile {
                display: flex;
                flex-direction: column;
                width:80%;
            }
            .NewsFeed_card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                width: 450px;
            
                min-height: 615px;
                background-color: #ffffff;
                border: 1px solid #dddddd;
                border-radius: 5px;
                padding: 20px;
                color: #333333;
                text-align: center;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                @include mobile {
                  width: 100%;
                  min-height: 312px;
                  background-color: #ffffff;
                  border: 1px solid #dddddd;
                  border-radius: 5px;
                  padding: 20px;
                  color: #333333;
                  text-align: center;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                  transition: transform 0.3s;
                }
              
                
                .NewsFeedCard_card__image {
                  width: 100%;
                  height: 265px;
                  max-height: 265px;
                  border-radius: 5px;
                  margin-bottom: 15px;
                  @include mobile {
                    max-width: 100%;
                    height: auto;
                  }
                
                }
                .NewsFeedCard_card__title {
                  font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 10px;
                }
                .NewsFeedCard_card-description {
                    width: 100%;
                  
                    height: fit-content;
                    text-align: justify;
                    word-wrap: break-word;
                    overflow:hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .NewsFeedCard_card__content {
                    height: fit-content;
                    width: 100%;
                  font-size: 1rem;
                  line-height: 1.5;
                  text-align: center;
                  @include mobile {
                    margin-top: 10px;
                  }
                }
                .NewsFeed_button {
                    width: 100%;
                    padding: 10px;
                    border-radius: 5px;
                    background-color: #333333;
                    color: white;
                    border:none;
                    cursor: pointer;
                }
              }
        }
       }
}