@import "../app.scss";

.bidsAndAwards {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }

  .bidsAndAwards_container {
    max-width: 1366px;

    padding: 16px;
    margin: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;

    .test {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      @include mobile {
        display: flex;
        flex-direction: column;
      }
    }
    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .bidsAndAwardContainer {
      width: 200px;
      height: 150px;
      border: 1px solid rgb(11, 109, 11);
      color: #1c1f23;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .icon {
        color: #1c1f23;
        width: 41px;
        height: 43px;
      }

      span {
        font-size: 12px;
        font-weight: bolder;
      }
    }
    .bidsAndAwardContainer:hover {
      .icon {
        color: white;
      }
      background-color: rgb(11, 109, 11);
      border: none;
      color: white;
      transition: 0.6s ease;
    }
  }
}
