@import '../app.scss';
.newsCard_card {
    width: 450px;

    min-height: 615px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 20px;
    color: #333333;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @include mobile {
      width: 100%;
      min-height: 312px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 5px;
      padding: 20px;
      color: #333333;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;
    }
  
    
    .newsCard_card__image {
      width: 100%;
      height: 265px;
      max-height: 265px;
      border-radius: 5px;
      margin-bottom: 15px;
      @include mobile {
        max-width: 100%;
        height: auto;
      }
    
    }
    .newsCard_card__title {
      font-size: 1.5rem;
font-weight: bold;
margin-bottom: 10px;
    }
    .newsCard_card-description {
        width: 100%;
      
        height: fit-content;
        text-align: justify;
        word-wrap: break-word;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .newsCard_card__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        min-height: 300px;
      
        width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      @include mobile {
        margin-top: 10px;
      }
      .newsCard_button {
        padding: 10px;
        border: none;
        background-color: #333333;
        color: white;
        border-radius: 5px;
      }
    }
  }