@import "../app.scss";
.latestNews {
  //  background-color: blue;
  //  overflow: hidden;
  //      max-width: 1366px;
  //     height: 100vh;
  //     color: white;
  //      margin: auto;
  //      padding-top: 25px;
  //         display: flex;
  //     flex-direction: column;
  max-width: 1366px;
  height: fit-content;
  height: auto;

  padding: 10px;
  padding-top: 25px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @include mobile {
    max-width: 1366px;
    margin: auto;
    padding-top: 25px;
    color: white;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    color: #1c1f23;
    @include mobile {
      font-size: 2rem;
      text-align: center;
      color: #1c1f23;
    }
  }
  .wrapper {
    width: 100%;
    max-height: fit-content;
    height: auto;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
    }

    .upContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      //  max-height: fit-content;
      //  height: auto;
      //  overflow-y: auto;
      gap: 20px;
      @include mobile {
        display: flex;
        height: fit-content !important;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        flex-direction: column;
        align-items: center;
      }
      .card {
        width: 450px;
        min-height: 312px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 20px;
        color: #333333;
        text-align: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        @include mobile {
          width: 450px;
          min-height: 312px;
          background-color: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 5px;
          padding: 20px;
          color: #333333;
          text-align: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s;
        }
        .btn {
          width: 100%;
          padding: 10px;
          background: #1c1f23;
          border: none;
          border-radius: 5px;
          color: white;

          @include mobile {
            max-width: 100%;
          }
        }

        .card__image {
          width: 100%;
          max-height: 265px !important;
          border-radius: 5px;
          margin-bottom: 15px;
          @include mobile {
            max-width: 100%;
            height: auto;
          }
        }
        .card__title {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .card__content {
          font-size: 1rem;
          line-height: 1.5;
          text-align: center;
          @include mobile {
            margin-top: 10px;
          }
          .btn {
            cursor: pointer;
          }
        }
      }
      .card:hover {
        transform: scale(1.05);
      }
    }
    .downContainer {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      @include mobile {
        flex-direction: column;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
      }
      .card {
        width: 450px;
        min-height: 312px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 20px;
        color: #333333;
        text-align: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        @include mobile {
          margin-bottom: 100px;
          text-align: center;
        }
        .card__image {
          max-width: 100%;
          height: 265px;
          border-radius: 5px;
          margin-bottom: 15px;
        }
        .card__title {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .card__content {
          font-size: 1rem;
          line-height: 1.5;
          text-align: center;
          @include mobile {
            margin-top: 10px;
          }
        }
        .btn {
          width: 100%;
          padding: 10px;
          background: #1c1f23;
          border: none;
          border-radius: 5px;
          color: white;
          cursor: pointer !important;
        }
      }
      .card:hover {
        transform: scale(1.05);
      }
    }
  }
}

@media (max-width: 768px) {
  .latestNews {
    max-height: fit-content;
    height: auto;
    overflow-y: auto;
  }
  .upContainer {
    height: fit-content;
    flex-direction: column;
    gap: 20px;
    .card {
      width: 100%;
      max-width: 300px;
      height: 300px;
      margin-bottom: 100px;
    }
  }

  .downContainer {
    flex-direction: column;
    gap: 20px;
    .card {
      width: 100%;
      max-width: 300px;
      height: 300px;
      overflow-y: scroll;
      .card__title {
        font-size: 5px;
      }
    }
  }
}
