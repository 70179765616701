@import '../app.scss';
.touristDestinations {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        height: auto;
        
       }
       .touristDestinations__container {
        max-width: 1366px;
        margin: auto;
        @include mobile {
            display: flex;
            height: fit-content !important;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            margin-bottom: 20px;
            flex-direction: column;
            align-items: center;
           }
        .touristDestinations__container_h1 {
            text-align: center;
        }
        .touristDestinations_cards_container {
            width: 100%;
            display: flex;
            align-items: center;justify-content: center;
            flex-wrap: wrap;
            gap: 5px;
        }
       }
}