@import '../app.scss';
.mayorSection {
    // background-color: rgb(245,245,246);
    max-width: 1366px;
    // height: 100%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    color: black;

    @include mobile {
    
    
      
      display: flex;
      flex-direction: column;
    }

    .left__container {
        background-color: #F5F5F6; 
        flex: 1;
        @include mobile {
          padding: 10px;
        }
        
        h1 {
            text-align: center;
            font-weight: bolder;
            font-size: 40px;
            @include mobile {
              font-size: 2rem;
      margin-bottom: 10px;
            }
        }

        p {
            font-weight: bold;
            font-size: 1rem;
            text-align: justify;
            padding: 10px;
            color: #696b7e;
            @include mobile {
              font-size: 1rem;
              color: #696b7e;
              margin-bottom: 15px;
            }
        }
        .third-p {
          @include mobile {
            margin-top: 15px;
          }
        }

        
        
    }

    .right__container {
        flex: 1;
        
        background-color: #F5F5F6;
        @include mobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
        }
        img {
            width: 800px;
            object-fit: cover;
            @include mobile {
              width: 100%;
              max-width: 400px;
              height: auto;
              border-radius: 5px;
              margin-bottom: 20px;
            }
          
        }

        .right_logo_container {
           
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include mobile {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            img {
                width: 100px;
                height: 60px;
                object-fit: contain;
                border: none;
                border-radius: 5px;
                @include mobile {
                  width: 100px;
                  height: 60px;
                  object-fit: contain;
                  border: none;
                  border-radius: 5px;
                  margin-right: 10px;
                }
            }
        }
    }
}