@import '../app.scss';

.localOfficials {
    width: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   .img_container {
    img {
        max-width: 100%;
        height: auto;
    }
   }
   .localOfficials__container {
    max-width: 1366px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
    
   }
   .localOfficials__sb_container {
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 15px;
    .localOfficials__sb_container_cards_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

    }
   }
}