@import '../app.scss';
// Variables
$max-width: 1170px;
$footer-bg-color: #24262b;
$link-color: #ffffff;
$link-hover-color: #ffffff;
$transition-speed: 0.3s;
$social-link-bg-color: rgba(255,255,255,0.2);
$social-link-hover-color: #ffffff;
$social-link-hover-bg-color: #24262b;

// Mixins
// @mixin transition($property) {
//   transition: $property $transition-speed ease;
// }

.full-container {
  max-width: 100%;
  height: 100%;
 
  position: relative;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  z-index: 998;
  

  .container {
    max-width: $max-width;
    margin: auto;
    display: flex;
    // flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
    overflow: hidden;
    @include mobile {
      flex-direction: column;
    }
  }

  .main-video-container {
    flex: 1 1 700px;
    background-color: #fff;
    padding: 15px;
    @include mobile {
      flex: 0;
    }
  }

  .main-video {
    margin-bottom: 7px;
    width: 100%;
  }

  .main-vid-title {
    font-size: 20px;
    color: #444;
  }

  .video-list-container {
    flex: 1 1 300px;
    border-left: 2px solid #eee;
    background-color: #fff;
    padding: 15px 15px 15px 35px;
    height: 500px;
    overflow-y: scroll;
  }

  .list {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
    border: 1px solid #eee;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .list:last-child {
    margin-bottom: 0;
  }

  .list.active {
    background-color: rgb(11, 109, 11);
  }

  .container .video-list-container .list.active .list-title {
    color: #fff;
  }

  .list-video {
    width: 100px;
    border-radius: 5px;
  }

  .list-title {
    font-size: 17px;
    color: #444;
  }
  @media (max-width: 768px) {
    .full-container .container {
      padding: 10px;
      
      .main-video-container {
        margin-bottom: 10px;
        
        .main-video {
          // Additional styling for main video on small screens if needed
        }
        
        .main-vid-title {
          // Additional styling for main video title on small screens if needed
        }
      }
      
      .video-list-container {
        .list {
          margin: 5px;
          
          .list-video {
            width: 80px; // Adjust as needed for your design
          }
          
          .list-title {
            font-size: 12px;
            // Additional styling for title on small screens if needed
          }
        }
      }
    }
  }


}



// /* VideoPlaylist.css */
// * {
//     font-family: 'Poppins', sans-serif;
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     text-transform: capitalize;
//   }
//   // Variables
// $max-width: 1170px;
// $footer-bg-color: #24262b;
// $link-color: #ffffff;
// $link-hover-color: #ffffff;
// $transition-speed: 0.3s;
// $social-link-bg-color: rgba(255,255,255,0.2);
// $social-link-hover-color: #ffffff;
// $social-link-hover-bg-color: #24262b;

// // Mixins
// // @mixin transition($property) {
// //   transition: $property $transition-speed ease;
// // }
// .full-container {
//     width: 99vw;
//     height: 100vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     // background: rgb(11, 109, 11);
//     // background: url(../images/background_logo.png);
//     // background-repeat: no-repeat;
//     // background-size: cover;
//     // background-position: center;
//     // filter: blur(5px);
//     // opacity: 0.8; 

  
//   .container {
  
//     max-width: 1200px;
//     margin: auto;
//     display: flex;
//     flex-wrap: wrap;
//     align-items: flex-start;
//     gap: 20px;
//   }
  
//   .main-video-container {
//     flex: 1 1 700px;
//     background-color: #fff;
//     padding: 15px;
//   }
  
//   .main-video {
//     margin-bottom: 7px;
//     width: 100%;
//   }
  
//   .main-vid-title {
//     font-size: 20px;
//     color: #444;
//   }
  
//   .video-list-container {
//     flex: 1 1 300px;
//     border-left: 2px solid #eee;
//     background-color: #fff;
//     padding: 15px 15px 15px 35px;
//     height: 500px;
//     overflow-y: scroll;
//   }
  
//   .list {
//     display: flex;
//     align-items: center;
//     gap: 15px;
//     padding: 10px;
//     border: 1px solid #eee;
//     cursor: pointer;
//     border-radius: 5px;
//     margin-bottom: 10px;
//   }
  
//   .list:last-child {
//     margin-bottom: 0;
//   }
  
//   .list.active {
//     background-color: rgb(11, 109, 11);
//   }
  
//   .container .video-list-container .list.active .list-title {
//     color: #fff;
//   }
  
//   .list-video {
//     width: 100px;
//     border-radius: 5px;
//   }
  
//   .list-title {
//     font-size: 17px;
//     color: #444;
//   }
  
//   @media (max-width:1200px) {
//     .container {
//       margin: 0;
//     }
  
//     .video-list-container {
//       border: none !important;
//       padding: 15px !important;
//     }
//   }
  
//   @media (max-width:450px) {
//     .video-list-container {
//       border: none !important;
//       padding: 15px !important;
//     }
  
//     .main-vid-title {
//       font-size: 15px;
//       text-align: center;
//     }
  
//     .list {
//       flex-flow: column;
//       gap: 10px;
//     }
  
//     .list-video {
//       width: 100%;
//     }
  
//     .list-title {
//       font-size: 15px;
//       text-align: center;
//     }
//   }
// }

