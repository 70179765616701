@import "../app.scss";

.invitationToBid {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 100%;
    height: auto;
  }

  @include mobile {
    max-width: 1366px;
    margin: auto;
    padding-top: 25px;
    color: white;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    color: #1c1f23;

    @include mobile {
      font-size: 2rem;
      text-align: center;
      color: #1c1f23;
    }
  }

  .wrapper {
    width: 100%;
    max-height: fit-content;
    height: auto;

    display: flex;
    flex-wrap: wrap; /* Use wrap to make it responsive */
    gap: 16px; /* Adjust the gap between images */

    .invitationToBid_img {
      flex: 1 1 calc(33.333% - 32px); /* 3 columns */
      max-width: 100%;
      height: auto; /* Set a fixed height for uniformity */
      object-fit: cover; /* Ensure the image covers the entire container */
    }
  }

  .photo-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;

    .gallery-item {
      position: relative;
      flex: 1 1 calc(33.333% - 32px); // 3 columns
      box-sizing: border-box;
      overflow: hidden;

      .gallery_img {
        width: 100%;
        height: 400px; /* Set the same fixed height for all images */
        object-fit: cover; /* Ensure the image covers the entire container */
        display: block;
      }

      .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        font-size: 16px;
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .wrapper,
    .photo-gallery {
      .invitationToBid_img,
      .gallery-item {
        flex: 1 1 calc(50% - 32px); // 2 columns
        height: 150px; /* Adjust the height for smaller screens */
      }
    }
  }

  @media (max-width: 480px) {
    .wrapper,
    .photo-gallery {
      .invitationToBid_img,
      .gallery-item {
        flex: 1 1 calc(100% - 32px); // 1 column
        height: 120px; /* Adjust the height for smaller screens */
      }
    }
  }
}
