@import "../app.scss";
.vmg__card {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 455px;

  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem;
  @include mobile {
    width: 100%;
  }
  .vmg__card1 {
    display: block;
    position: relative;
    max-width: 600px;
    /* background-color: #f2f8f9; */
    background-color: white;
    border-radius: 4px;
    padding: 32px 24px;
    /* margin: 12px; */
    text-decoration: none;
    z-index: 0;
    overflow: hidden;

    .vmg__h3 {
      text-align: center;
      color: white;
      background-color: #212529;
      font-weight: bolder;
      padding: 10px;
    }

    .vmg__go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 32px;
      height: 32px;
      overflow: hidden;
      top: 0;
      right: 0;
      background-color: #2e7d32;
      border-radius: 0 4px 0 32px;
      .vmg__go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: white;
        font-family: courier, sans;
      }
    }
  }
  .vmg__card1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #212529;
    color: white;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  .vmg__card1:hover:before {
    transform: scale(40);
  }

  .vmg__card1:hover p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .vmg__card1:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff !important;
  }
}
