.DirectoryCard {
    width: 414px;
    height: 200px;
    border: 1px solid rgb(11, 109, 11);
    color: #1C1F23;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  
    .DirectoryCard_h3 {
        text-align: center;
        background-color: #1C1F23;
        color: white;
        padding: 5px;
        width: 90%;
        height: 78px;
        border-radius: 5px;
    }

    .DirectoryCard_span {
      font-size: 18px;
      font-weight: bolder;
      
    }
  }

  .DirectoryCard:hover {
    .DirectoryCard_h3 {
      color: white;
    }
    background-color: rgb(11, 109, 11);
    border: none;
    color: white;
    transition: 0.6s ease;
  }