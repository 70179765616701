// styles.scss

// Variables
$container-max-width: 1170px;
$footer-bg-color: rgb(11, 109, 11);
$link-color: #ffffff;
$link-hover-color: #ffffff;
$social-link-bg-color: #ffffff33;
$social-link-hover-color: #24262b;
$social-link-hover-bg-color: #ffffff;

// Mixins
@mixin transition($property) {
  transition: $property 0.3s ease;
}

// Styles
.container {
  max-width: $container-max-width;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

.footer {
  background-color: $footer-bg-color;
  padding: 70px 0;
    width: 100%;
  .footer-col {
    width: 25%;
    padding: 0 15px;
    img {
        width: 50px;
        height: 50px;
    }

    .serbisyo_img {
        width: 150px;
    }

    h4 {
      font-size: 18px;
      color: $link-color;
      text-transform: capitalize;
      margin-bottom: 35px;
      font-weight: 500;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: #24262b;
        height: 2px;
        box-sizing: border-box;
        width: 50px;
      }
    }

    ul li:not(:last-child) {
      margin-bottom: 10px;
    }

    ul li a {
      font-size: 16px;
      text-transform: capitalize;
      color: $link-color;
      text-decoration: none;
      font-weight: 300;
      color: white;
      display: block;
      @include transition(all);

      &:hover {
        color: $link-hover-color;
        padding-left: 8px;
      }
    }

    .social-links a {
      display: inline-block;
      height: 40px;
      width: 40px;
      background-color: $social-link-bg-color;
      margin: 0 10px 10px 0;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      color: $link-color;
      @include transition(all);

      &:hover {
        color: $social-link-hover-color;
        background-color: $social-link-hover-bg-color;
      }
    }
  }
}

// Responsive
@media (max-width: 767px) {
  .footer .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 574px) {
  .footer .footer-col {
    width: 100%;
  }
}
