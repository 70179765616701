@import '../app.scss';
.festival {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
        max-width: 100%;
        height: auto;
        
       }
       @include mobile {
        .article-text {
         font-size: 18px;
         text-align: justify;
         padding:0 10px;
        }
        }
        .container {
            max-width: 1033px;
            margin: auto;
        
            .article {
                display: flex;
                flex-direction: column;
              
                @media screen and (min-width: 768px) {
                  flex-direction: row;
                }
              
                .article-image {
                  width: 100%;
                  margin-bottom: 20px;
              
                  img {
                    width: 100%;
                    height: auto;
                  }
              
                  @media screen and (min-width: 768px) {
                    width: 50%;
                    margin-bottom: 0;
                    margin-right: 20px;
                  }
                }
              
                .article-content {
                  width: 100%;
              
                  @media screen and (min-width: 768px) {
                    width: 50%;
                  }
              
                  .article-title {
                    text-align: center;
                    font-size: 24px;
                    margin-bottom: 10px;
                    span {
                        font-size: 20px;
                        text-align: center;
                    }
                    @media screen and (min-width: 768px) {
                      font-size: 28px;
                    }
                  }
              
                  .article-text {
                    font-size: 16px;
                    text-align: center;
                    @media screen and (max-width: 768px) {
                      font-size: 18px;
                      text-align: justify;
                    }
                  }
                }
              }
         
              
           }
}