.progressMainWrapper {
    background-color: rgb(11, 109, 11);
    height: 15px;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index:1000 !important;
    width: 100%;

    .progressMainStyle {
        height: 15px;
        /* background-color: rgb(28, 85, 21); */
        background-image: linear-gradient(to right,green, orange);
      
    }
}