@import '../app.scss';
.accounting {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .accounting_header_container {
        .accounting_img {
            max-width: 100%;
            height: auto;
            
        }
    }
  
    .accounting_container {
        max-width: 1366px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    
        
       .accounting_vmg_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @include mobile {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
        }
       }
       .accounting_tableContainer {
        width: 100%;
        
        
        .accounting_h1 {
            text-align: center;
            text-transform: capitalize;
            @include mobile {
                text-align: left;
                font-size: 20px;
                margin-left: 13px;
            }
        }
        .accounting_p {
            text-align: center;
            text-transform: capitalize;
        }
       }
    }
}