.LGUOffices {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .LGUOffices__header_container {
        .LGUOffices__img {
            max-width: 100%;
            height: auto;
        }
       }
    .LGUOffices__container {
        max-width: 1366px;
        margin: auto;
        .LGUOffices__h1 {
            text-align: center;
        }
        .LGUOffices__offices_container {
            display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
          .LGUOffices__box_container {
            width: 200px;
            height: 150px;
            border: 1px solid rgb(11, 109, 11);
            color: #1C1F23;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
    
            .icon {
              color: #1C1F23;
              width: 41px;
              height: 43px;
            }
    
            span {
              font-size: 12px;
              font-weight: bolder;
              text-align: center;
            }
          }
          .LGUOffices__box_container:hover {
            .icon {
              color: white;
            }
            background-color: rgb(11, 109, 11);
            border: none;
            color: white;
            transition: 0.6s ease;
          }
        }
     
    
    }
}