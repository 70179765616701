// styles.scss

html {
  
    scroll-behavior: smooth;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  body {
    background-color: white; // Change background color
    color: black; // Change text color
  }
  
  .app {
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
  
    .header__container {
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        object-fit: contain;
        max-width: 100%;
        height: auto;

      }
    }
  
    .background__container {
     
      height: calc(100vh - 388px); // Adjust height for smaller screens
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      
     
  
      button {
        width: 100px;
        padding: 10px;
      }
  
      .previous_btn {
        position:absolute;
        top: 80%; // Adjust positioning for smaller screens
        left: 45%; // Adjust positioning for smaller screens
      }
  
      .next_btn {
        position: absolute;
        top: 80%; // Adjust positioning for smaller screens
        right: 45%; // Adjust positioning for smaller screens
      }
  
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(100vh - 385px);
       
        object-fit: cover;
        animation: scale 20s infinite;
     
      }
  
      @keyframes scale {
        50% {
          transform: scale(1.2);
        }
      }
    }
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  // section {
  // height: 100%;
  // }


// mobile
@mixin mobile {
    @media(max-width: 768px) {
        @content;
    }
}
@media only screen and (max-width: 768px) {
 .app {
  flex-wrap: wrap;
 }
  .background__container img {
    width: 100%;
  }
}

@mixin medium {
  @media(max-width:1518px) {
    @content;
  }
}

@mixin small {
  @media(width: 375px) {
    @content;
  }
}


// tablet
@mixin tablet {
    @media(max-width: 1024px) {
        @content;
    }
}
// desktop
@mixin desktop {
    @media(max-width: 1366px) {
        @content;
    }
}

// /* Extra small devices (phones, 576px and down) */
// @media (max-width: 576px) {
//     /* CSS styles for extra small devices */
//   }
  
//   /* Small devices (portrait tablets and large phones, 576px and up) */
//   @media (min-width: 576px) {
//     /* CSS styles for small devices */
//   }
  
//   /* Medium devices (landscape tablets, 768px and up) */
//   @media (min-width: 768px) {
//     /* CSS styles for medium devices */
//   }
  
//   /* Large devices (laptops/desktops, 992px and up) */
//   @media (min-width: 992px) {
//     /* CSS styles for large devices */
//   }
  
//   /* Extra large devices (large laptops and desktops, 1200px and up) */
//   @media (min-width: 1200px) {
//     /* CSS styles for extra large devices */
//   }