@import '../app.scss';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px 20px;
        @include mobile {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 10px 10px;
        }
        a {
            text-decoration: none;
            cursor: pointer;
        }
        .plansAndProgramContainer {
            width: 200px;
            height: 150px;
            border: 1px solid rgb(11, 109, 11);
            color: #1C1F23;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
    
            .icon {
              color: #1C1F23;
              width: 41px;
              height: 43px;
            }
    
            span {
              font-size: 12px;
              font-weight: bolder;
              
            }
          }
    
          .plansAndProgramContainer:hover {
            .icon {
              color: white;
            }
            background-color: rgb(11, 109, 11);
            border: none;
            color: white;
            transition: 0.6s ease;
          }
    }
    

      button {
        padding: 10px;
        margin-top: 20px;
        width: 100%;
        background-color: #1C1F23;
        color: white;
        border: none;
        border-radius: 5px;
      }
    
  }
  