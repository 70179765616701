.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    width: 50px;
    height: 50px;
    // background-color: rgb(11, 109, 11);
    background-color: #1C1F23 ;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
   
  }
  
  .back-to-top-button:hover {
    background-color: rgb(11, 109, 11);
  }