.bacLoginButton {
  .bac-login-button {
    z-index: 1000;
    position: fixed;
    bottom: 80px;
    right: 20px;
    padding: 10px;
    width: 50px;
    height: 50px;
    // background-color: rgb(11, 109, 11);
    background-color: orange;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;

    .icon {
      font-size: 1.5rem;
    }
  }

  .bac-login-button:hover {
    background-color: rgb(11, 109, 11);
  }
}
