// styles.scss
@import '../app.scss';
.awardsSection {
    max-width: 1366px;
    height: fit-content;
    padding: 10px;
    padding-top: 25px;
    margin: auto;
    display: flex;
    flex-direction: column;
    color:white;
   
    .upContainer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      // flex-wrap: wrap;
     
      @include mobile {
        flex-direction: column;
        
      }
      .card {
        width: 450px;
        min-height: 312px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 20px;
        color: #333333;
        text-align: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        @include mobile {
          margin-bottom: 100px;
          text-align: center;
        }
        .card__image {
          max-width: 100%;
          height: 265px;
          border-radius: 5px;
          margin-bottom: 15px;
        }
        .card__title {
          font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
        }
        .card__content {
       
          font-size: 1rem;
          line-height: 1.5;
          text-align: center;
          @include mobile {
            margin-top: 10px;
          }
        }
      }
  
    
    }
  
    .downContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
  
      .leftContainer {
        padding: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
  
        h2 {
          color: #1C1F23;
          font-size: 2rem;
        }
  
        .container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
        }
  
        .plansAndProgramContainer {
          width: 200px;
          height: 150px;
          border: 1px solid rgb(11, 109, 11);
          color: #1C1F23;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
  
          .icon {
            color: #1C1F23;
            width: 41px;
            height: 43px;
          }
  
          span {
            font-size: 12px;
            font-weight: bolder;
            
          }
        }
  
        .plansAndProgramContainer:hover {
          .icon {
            color: white;
          }
          background-color: rgb(11, 109, 11);
          border: none;
          color: white;
          transition: 0.6s ease;
        }
      }
  
      .rightContainer {
        padding: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
  
        .upper {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-size: 2rem;
            color: #1C1F23;
            margin-bottom: 10px;
          }
        }
  
        .middle {
          flex: 1;
          p {
            color: #1C1F23;
          }
        }
  
        .bottom {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
  
          .plansAndProgramContainer {
            width: 200px;
            height: 150px;
            border: 1px solid rgb(11, 109, 11);
            color: #1C1F23;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
  
            .icon {
              color: #1C1F23;
              width: 41px;
              height: 43px;
            }
  
            span {
              font-size: 12px;
              font-weight: bolder;
            }
          }
  
          .plansAndProgramContainer:hover {
            .icon {
              color: white;
            }
            background-color: rgb(11, 109, 11);
            border: none;
            color: white;
            transition: 0.6s ease;
          }
        }
      }
    }
  
    // Media query for responsiveness
    @media (max-width: 768px) {
      .upContainer {
        flex-direction: column;
        gap: 20px;
        .card {
          width: 100%;
          max-width: 300px;
          height: 300px;
        }
      }
  
      .downContainer {
        .leftContainer, .rightContainer {
          flex: 1;
          padding: 10px;
          width: 100%;
        }
  
        .leftContainer {
          gap: 10px;
          .plansAndProgramContainer {
            width: calc(50% - 20px);
          }
        }
  
        .rightContainer {
          .upper, .bottom {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
          }
          .upper h2 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  

// .awardsSection {
    
//      max-width: 1366px;
//      height: 100vh;
//      padding-top: 25px;
//      margin: auto;
//      display: flex;
//      flex-direction: column;
    
//     overflow: hidden;
//     //  up container
//     .upContainer {
//         flex: 1;
       
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         gap: 20px;;
        
//         .card {
//             width: 400px;
//             height: 400px;
//             position: relative;
//             overflow: hidden;
//             border-radius: 10px;
//             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//             transition: transform 0.3s;

//             .card-image {
//                 width: 100%;
//                 height: 70%;
//                 object-fit: cover;
//                 border-top-left-radius: 10px;
//                 border-top-right-radius: 10px;
//               }
//               .card-content {
//                 padding: 20px;
//                 text-align: center;
//                 background-color: rgb(11, 109, 11);
//                 position: absolute;
//                 bottom: 0;
//                 left: 0;
//                 right: 0;
//                 height: 30%;
//                 border-bottom-left-radius: 10px;
//                 border-bottom-right-radius: 10px;
//               }
//               .card-title {
//                 font-size: 1.5rem;
//                 margin-bottom: 10px;
//               }
              
//         }
//         .card:hover {
//             transform: scale(1.05);
//           }
//     }

//     //  down container
//     .downContainer {
//         flex: 1;
       
//         display: flex;

//         .leftContainer {
//             padding: 10px;
//             flex: 1;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             flex-wrap: wrap;
//             gap: 10px;

//            h2 {
//             color: #1C1F23;
//             font-size: 2rem;
//            }

//            .container {
          
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             gap: 10px;
//             flex-wrap: wrap;
//            }
           

//             .plansAndProgramContainer {
//                 width: 200px;
//                 height: 150px;
//                 border: 1px solid rgb(11, 109, 11);
//                 color: #1C1F23;
//                 display: flex;
//                 flex-direction: column;
//                 flex-wrap: wrap;
//                 align-items: center;
//                 justify-content: center;

//                 .icon {
//                     color: #1C1F23;
//                     width: 41px;
//                     height: 43px;
//                 }

//                 span {
//                     font-size: 12px;
//                     font-weight: bolder;
//                 }
//             }
//             .plansAndProgramContainer:hover {

//                 .icon{
//                     color: white;
//                 }
//                 background-color: rgb(11, 109, 11);
//                 border: none;
//                 color: white;
//                 transition: 0.6s ease;
//             }
//         }

//         .rightContainer{
           
//             flex: 1;
//             padding: 10px;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: space-around;
            

           

//             .upper {
//                 flex: 1;
               
//                 display: flex;
//                 align-items: center;
//                 h2 {
//                     font-size: 2rem;
//                     color: #1C1F23;
//                     margin-bottom: 10px;
                
//                 }
//             }
//             .middle {
//                     flex: 1;
//                     p {
//                         color: #1C1F23
//                     }
//             }
//             .bottom{
//                 flex: 1;
                
//                 width: 100%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 gap: 10px;
//                 .plansAndProgramContainer {
//                     width: 200px;
//                     height: 150px;
//                     border: 1px solid rgb(11, 109, 11);
//                     color: #1C1F23;
//                     display: flex;
//                     flex-direction: column;
//                     flex-wrap: wrap;
//                     align-items: center;
//                     justify-content: center;
    
//                     .icon {
//                         color: #1C1F23;
//                         width: 41px;
//                         height: 43px;
//                     }
    
//                     span {
//                         font-size: 12px;
//                         font-weight: bolder;
//                     }
//                 }
//                 .plansAndProgramContainer:hover {
    
//                     .icon{
//                         color: white;
//                     }
//                     background-color: rgb(11, 109, 11);
//                     border: none;
//                     color: white;
//                     transition: 0.6s ease;
//                 }
//             }

//         }
//     }
// }


// for resoponsiveness
// @media (max-width: 768px) {
//     .card {
//       width: 100%;
//       height: auto;
//     }

//     .card-image {
//       height: 50%;
//     }
//   }