// ResponsiveTable.scss

.table-container {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
   
    th,
    td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: rgb(11, 109, 11);
      color: white;
    }
  }
  