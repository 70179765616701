.localOfficials__card {
    width: 100%;
    max-width: 400px; // Set maximum width for responsiveness
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(122, 43, 43, 0.1);
    // overflow: hidden;

    .localOfficials__img {
        width: 100%;
        height: auto;
        display: block;
    }
    .localOfficials__card_content {
        padding: 20px;
        background-color: #1C1F23;
        .localOfficials__card_title {
          font-size: 1.5rem;
          font-weight: bolder;
          margin-bottom: 10px;
          text-align: center;
          color: white
        }
        .localOfficials__card_text {
            font-size: 1rem;
            color: white;
            font-weight: bold;
            text-align: center;
        }
    
      
      }
      

}
// .localOfficials__card:hover {
//     transform: scale(1.05);
// }