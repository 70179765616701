@import '../app.scss';
.directory {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .directory__header_container {
        .directory__img {
            max-width: 100%;
            height: auto;
        }
       }
    .directory__container {
        max-width: 1366px;
        margin: auto;
        @include mobile {
            width: 100%;
            display: flex;
            height: fit-content !important;
            // flex-wrap: wrap;
            justify-content: center;
            // gap: 10px;
            margin-bottom: 20px;
            flex-direction: column;
            align-items: center;
           }
        .directory__h1 {
            text-align: center;
        }
        .directory__offices_container {
            display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
          
        
        }
     
    
    }
}