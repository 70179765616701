.subMenu {
  

    a {
        display: flex;
        color: #e1e9fc;
        justify-content: center;
        align-items: center;
      
        padding: 20px;
        list-style: none;
        height: 60px;
        text-decoration: none;
        font-size: 18px;
    }
    a:hover {
        background: #252831;
        // border-left: 4px solid #632ce4;
        cursor: pointer;
       
    }
    span {
        margin-left: 16px;
        text-align: center;
    }

    .subNav {
        background-color: #252831;
        text-align: center;
       
    }

     

    

   
}